import React, { useState, useEffect } from "react";
import backgound_pic from "../../assets/handbookbg.png";
import "./style.scoped.scss";
import "./loader.scss";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import central_rounded_logo from "../../assets/logo_round_white.png";
import axios from "axios";

function CavalierForm() {
	const [loading, setLoading] = useState(false);

	const [data, setData] = useState([]);

	const [lastname, setNom] = useState("");
	const [firstname, setPrenom] = useState("");
	const [address, setAdresse] = useState("");
	const [cp, setCp] = useState("");
	const [city, setCommune] = useState("");
	const [email, setEmail] = useState("");
	const [assurance, setAssurance] = useState("");
	const [date_validity, setDateValidite] = useState("");
	const [horse_name, sethorse_name] = useState("");
	const [horse_sex, sethorse_sex] = useState("");
	const [horse_birthdate, sethorse_birthdate] = useState("");
	const [sire, setsire] = useState("");
	const [code_transponder, setCode_transponder] = useState("");
	const [horse_address, sethorse_address] = useState("");
	const [horse_cp, setCpLieuDetention] = useState("");
	const [horse_city, setHorseCity] = useState("");
	const [formErrors, setFormErrors] = useState({});
	const [reglement, setReglement] = useState("");
	const [attendants, setAttendants] = useState("0");
	const navigate = useNavigate();
	const location = useLocation();

	const queryParams = new URLSearchParams(location.search);

	//const [modalIsOpen, setIsOpen] = useState(false);

	/*	ReactModal.defaultStyles.overlay.backgroundColor = "#000000CC";
	const customModalStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			backgroundColor: "white",
			border: "1px solid black",
			color: "black",
		},
	};
*/
	const textError = "obligatoire";

	const [modalIsOpen, setIsOpen] = useState(false);

    // Not used
	// const preHandleSubmitModal = (status) => {
	// 	setIsOpen(status);
	// };

	const handleSubmit = (event) => {
		setLoading(true);

		// submit the form data to the server
		const formData = {
			lastname,
			firstname,
			address,
			cp,
			city,
			email,
			assurance,
			date_validity,
			horse_name,
			horse_sex,
			horse_birthdate,
			sire,
			code_transponder,
			horse_address,
			horse_cp,
			horse_city,
			reglement,
			attendants,
			//session,
			event_id: queryParams.get("id"),
			event_date: queryParams.get("date"),
			event_session: queryParams.get("session"),
		};

		event.preventDefault();
		// perform form validation
		let errors = {};

		if (!firstname) {
			errors.nom = `Nom ${textError}`;
		}

		if (!lastname) {
			errors.prenom = `Prenom ${textError}`;
		}

		if (!address) {
			errors.adresse = `Adresse ${textError}`;
		}

		if (!email) {
			errors.email = `Email ${textError}`;
		} else if (!/\S+@\S+\.\S+/.test(email)) {
			errors.email = "Invalid email format";
		}

		if (!assurance) {
			errors.assurance = `Assurance ${textError}`;
		}

		if (!date_validity) {
			errors.dateValidite = `Validite ${textError}`;
		}

		if (!horse_name) {
			errors.horse_name = `Nom du cheval ${textError}`;
		}

		if (!horse_sex) {
			errors.horse_sex = `Champs ${textError}`;
		}

		if (!horse_birthdate) {
			errors.horse_birthdate = `Date de Naissance ${textError}`;
		}

		if (!sire || sire.length < 9) {
			errors.sire = `invalide sire (9 chiffres)`;
		}

		if (!code_transponder || code_transponder.length < 15) {
			errors.code_transponder = `Invalide Transpondeur (15 chiffres)`;
		}

		if (!horse_address) {
			errors.horse_address = `Lieu de détention ${textError}`;
		}

		if (!reglement) {
			errors.reglement = `Le réglement intérieur doit être accepté`;
		}

		if (attendants > 3) {
			errors.attendants = `Le nombre d'accompagnants est limité à 3`;
		}

		if (Object.keys(errors).length !== 0) {
			setFormErrors(errors);
			setLoading(false);
			return;
		}

		fetch(process.env.REACT_APP_BACKEND_URL + "/submit-form-data", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(formData),
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error("Network response was not ok");
				}
				return response.json();
			})
			.then((data) => {
				//setIsOpen(true);
				// Success continue to stripe
				handleStripe({ ...formData, id: data.attendee_id });
			})
			.catch((error) => {
				console.error("There was a problem submitting the form data:", error);
				setLoading(false);
			});
	};

	// async function to send data to stripe
	async function handleStripe(formData) {
		fetch(process.env.REACT_APP_BACKEND_URL + "/checkout-service", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(formData),
		})
			.then((response) => {
				if (!response.ok) {
					setLoading(false);
					throw new Error("Network response was not ok");
				}
				return response.json();
			})
			.then((data) => {
				//console.log(data);
				try {
					let cancelUrl = `${process.env.REACT_APP_DOMAIN}/validation?success=false&event_id=${data.event_id}&attendee_id=${data.attendee_id}`;
					//console.log(cancelUrl);
					window.history.pushState(null, null, cancelUrl);
				} catch (e) {
					console.log(
						"Browser prevents window.history.pushState(). In a result, browser back button won't cancel the registration and expiration will have to occur automatically"
					);
				}
				window.location.href = data.url;
				//window.location.replace(data.url);
			})
			.catch((error) => {
				console.error("There was a problem submitting the form data:", error);
				setLoading(false);
			});
	}

	function handleCodeSubmit() {
		// send code to server and wait for response
		// if response is true, then navigate to application page
		// else, display error message
		axios
			.post(process.env.REACT_APP_BACKEND_URL + "/event_lock", {
				id: queryParams.get("id"),
				code: queryParams.get("locknkey"),
			})
			.then((res) => {
				if (res.status === 200) {
				} else {
					navigate("/inscriptions");
				}
			});
	}

	useEffect(() => {
		handleCodeSubmit();

		axios
			.get(process.env.REACT_APP_BACKEND_URL + "/events")
			.then((response) => {
				let session = response.data.filter((item) => item.id == queryParams.get("id"));
				session[0].date = session[0].date.replace(/ /g, "T");
				setData(session[0]);
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

	function clampAttendants(value) {
		let count = parseInt(value);
		count = isNaN(count) ? 0 : count;
		count = clamp(count, 0, 3);

		setAttendants(count);
	}

	function getTotal() {
		return data.tarif + attendants * 8;
	}

	return (
		<div className="global background-pic cavalierform">
			<img id="background" src={backgound_pic} alt="" />
			<div className="divider" />

			<div className="flex-container">
				<div className="force-column">
					<img src={central_rounded_logo} alt="" />
				</div>

				<div className="recap">
					<div>{new Date(data.date).toLocaleDateString("fr-FR", { day: "numeric", month: "long", year: "numeric" })}</div>
					<div>{data.session}</div>
					<div style={{ fontSize: "0.7em" }}>{getTotal() + "€ TTC"}</div>
					<div style={{ fontSize: "0.6em" }}>(Ouverture du parking 15mn avant)</div>
					<div style={{ fontSize: "0.5em", marginTop: "10px" }}>
						<Link to="https://yellowbluestar.com/reglement" target="_blank" className="link" rel="noreferrer">
							Réglement Intérieur
						</Link>
					</div>
				</div>
				<form className={isMobile ? "form-container mark" : "form-container mark blur"} onSubmit={handleSubmit}>
					<div className="sub-container">
						<div className="left">
							<label htmlFor="" className="title">
								Information du Cavalier
							</label>
							<div className="form-field">
								<label htmlFor="nom">Nom :</label>
								<input type="text" id="nom" value={lastname} onChange={(e) => setNom(e.target.value)} />
								{formErrors.nom && <span className="error-message">{formErrors.nom}</span>}
							</div>
							<div className="form-field">
								<label htmlFor="prenom">Prenom :</label>
								<input type="text" id="prenom" value={firstname} onChange={(e) => setPrenom(e.target.value)} />
								{formErrors.prenom && <span className="error-message">{formErrors.prenom}</span>}
							</div>
							<div className="form-field">
								<label htmlFor="adresse">Adresse :</label>
								<div className="input-list">
									<input type="text" id="adresse" value={address} onChange={(e) => setAdresse(e.target.value)} />
									<div className="bot">
										<input type="text" id="CP" value={cp} onChange={(e) => setCp(e.target.value)} />
										<input type="text" id="Commune" value={city} onChange={(e) => setCommune(e.target.value)} />
									</div>
								</div>
								{formErrors.adresse && <span className="error-message">{formErrors.adresse}</span>}
							</div>
							<div className="form-field">
								<label htmlFor="email">Email :</label>
								<input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
								{formErrors.email && <span className="error-message">{formErrors.email}</span>}
							</div>
							<div className="form-field">
								<label htmlFor="assurance">N° d’assurance en action d’équitation (type FFE, Equiliberté, RC Pro)</label>
								<input type="text" id="assurance" value={assurance} onChange={(e) => setAssurance(e.target.value)} />
								{formErrors.assurance && <span className="error-message">{formErrors.assurance}</span>}
							</div>
							<div className="form-field">
								<label htmlFor="dateValidite">Date de validité :</label>
								<input type="date" id="dateValidite" value={date_validity} onChange={(e) => setDateValidite(e.target.value)} />
								{formErrors.dateValidite && <span className="error-message">{formErrors.dateValidite}</span>}
							</div>
						</div>
						<div className="right">
							<label htmlFor="" className="title">
								Information du Cheval
							</label>

							<div className="form-field">
								<label htmlFor="horse_name">Nom du cheval :</label>
								<input type="text" id="horse_name" value={horse_name} onChange={(e) => sethorse_name(e.target.value)} />
								{formErrors.horse_name && <span className="error-message">{formErrors.horse_name}</span>}
								<div className="radio-group">
									<div className="radio-item">
										<input
											type="radio"
											id="jument"
											name="horse_sex"
											value="Jument"
											checked={horse_sex === "Jument"}
											onChange={(e) => sethorse_sex(e.target.value)}
										/>
										<label htmlFor="jument">Jument</label>
									</div>

									<div className="radio-item">
										<input
											type="radio"
											id="entier"
											name="horse_sex"
											value="Entier"
											checked={horse_sex === "Entier"}
											onChange={(e) => sethorse_sex(e.target.value)}
										/>
										<label htmlFor="entier">Entier</label>
									</div>

									<div className="radio-item">
										<input
											type="radio"
											id="hongre"
											name="horse_sex"
											value="Hongre"
											checked={horse_sex === "Hongre"}
											onChange={(e) => sethorse_sex(e.target.value)}
										/>
										<label htmlFor="hongre">Hongre</label>
									</div>
								</div>
								{formErrors.horse_sex && <span className="error-message">{formErrors.horse_sex}</span>}
							</div>
							<div className="form-field">
								<label htmlFor="horse_birthdate">Date de naissance :</label>
								<input type="date" id="horse_birthdate" value={horse_birthdate} onChange={(e) => sethorse_birthdate(e.target.value)} />
								{formErrors.horse_birthdate && <span className="error-message">{formErrors.horse_birthdate}</span>}
							</div>
							<div className="form-field">
								<label htmlFor="sire">Sire :</label>
								<input type="text" id="sire" value={sire} onChange={(e) => setsire(e.target.value)} />
								{formErrors.sire && <span className="error-message">{formErrors.sire}</span>}
							</div>
							<div className="form-field">
								<label htmlFor="code_transponder">Code transpondeur :</label>
								<input type="text" id="code_transponder" value={code_transponder} onChange={(e) => setCode_transponder(e.target.value)} />
								{formErrors.code_transponder && <span className="error-message">{formErrors.code_transponder}</span>}
							</div>
							<div className="form-field">
								<label htmlFor="horse_address">Adresse du Lieu de détention :</label>
								<div className="input-list">
									<input type="text" id="adresse" value={horse_address} onChange={(e) => sethorse_address(e.target.value)} />
									<div className="bot">
										<input type="text" id="CP" value={horse_cp} onChange={(e) => setCpLieuDetention(e.target.value)} />
										<input type="text" id="Commune" value={horse_city} onChange={(e) => setHorseCity(e.target.value)} />
									</div>
								</div>
								{formErrors.horse_address && <span className="error-message">{formErrors.horse_address}</span>}
							</div>
							<div className="form-field">
								<label htmlFor="attendants">Nombre d'accompagnants (3 maximum, supplément de 8€ par personne) :</label>
								<input type="text" id="attendants" value={attendants} onKeyDown={(e) => clampAttendants(e.key)} />
								{formErrors.code_transponder && <span className="error-message">{formErrors.attendants}</span>}
							</div>

							<div className="form-field">
								<div className="ffhorizontal">
									<label htmlFor="reglement">
										J'ai lu et j'accepte les{" "}
										<Link to="https://yellowbluestar.com/reglement" target="_blank" className="link" rel="noreferrer">
											termes
										</Link>{" "}
										du règlement intérieur :
									</label>
									<input type="checkbox" id="reglement" value={reglement} onChange={(e) => setReglement(e.target.checked)} />
								</div>
								{formErrors.reglement && <span className="error-message">{formErrors.reglement}</span>}
							</div>
						</div>
					</div>

					{modalIsOpen ? (
						<>
							<div className="submit-container">
								<button type="submit" className="payment">
									Confirmer le paiement
								</button>
								<button className="back-home" onClick={() => setIsOpen(false)}>
									Annuler{" "}
								</button>
							</div>
							<div className="alert">
								Pour rappel le domaine est integralement non fumeur, les chiens ne sont pas admis et toute personne accompagnant sans cheval
								doit etre inscrite en tant qu'accompagnant. Vous pouvez pratiquer la session à pied et/ou monté. L'important est votre securité
								et celle de votre cheval, nous vous demandons donc de lui presenter d'abord le lieu en etant à pied.{" "}
							</div>
						</>
					) : (
						<>
							<div className="submit-container">
								<button className="payment" onClick={() => setIsOpen(true)}>
									Paiement ({getTotal()}€)
								</button>
								<Link to="/inscriptions" className="back-home" style={{ textDecoration: "none" }}>
									Retour
								</Link>
							</div>
						</>
					)}

					{loading ? (
						<div className="loader-container">
							<div className="spinner" />
						</div>
					) : (
						<div />
					)}
				</form>
			</div>
			<div style={{ height: "5em" }} />
		</div>
	);
}

export default CavalierForm;
